import React, { useEffect, useState } from "react"
import { Box } from "@mui/material"
import { useConnectWallet } from "@web3-onboard/react"
import {BTDMD_CONTRACT_ABI} from "./config/btdmdContractAbi"
import { REDEMPTION_CONTRACT_ABI } from "./config/redemptionContractAbi"
import { ethers } from "ethers"

/* global BigInt */

const Redemption = () => {
  const [balance, setBalance] = useState(0)
  const [redeemingStatus, setRedeemingStatus] = useState("idle")

  // Wallets and providers:
  const [{ wallet }, connect, disconnect] = useConnectWallet()

  const fetchBalance = async () => {
    const balance = await getUserBalance()
    if (balance) {
      setBalance(balance)
    }
  }

  useEffect(() => {
    console.log("fetchBalance")
    fetchBalance()
  }, )
  

  const handleConnect = async (
  ) => {
    if (!wallet) {
      await connect()
    } else {
      await disconnect(wallet)
    }
  }


  const handleRedeem = async (
    ) => {
      // Connect the wallet
      if (!wallet) {
        await connect()
      } else {

        console.log(wallet)

        if (!wallet) return

        const userAddress = wallet.accounts[0].address
    
        const provider = new ethers.BrowserProvider(wallet.provider, "any")
    
        const chainId = (await provider.getNetwork()).chainId
    
        if (Number(chainId) !== 56) {
          window.alert("Please connect to Binance Smart Chain (BSC)");
          return
        }
    
        console.log(chainId)
    
        const signer = await provider.getSigner()
    
        const BTDMDContract = new ethers.Contract(
          "0x669288ADA63ed65Eb3770f1c9eeB8956deDAaa47",
          BTDMD_CONTRACT_ABI,
          signer,
        )
        
        try {    
          const redemptionWithSigner = new ethers.Contract(
            "0x692937e390C48600D908F0222108769449C3620C",
            REDEMPTION_CONTRACT_ABI,
            signer,
          )
    
          const allowance = await BTDMDContract
            .allowance(userAddress, "0x692937e390C48600D908F0222108769449C3620C")
    
          if (allowance < balance) {
            console.log(userAddress)
            console.log(BTDMDContract)
            console.log("0x669288ADA63ed65Eb3770f1c9eeB8956deDAaa47")
            console.log(await BTDMDContract.symbol())
            setRedeemingStatus("Approving")
    
            // Make the contract call
            const tx1 = await BTDMDContract
              .connect(signer)
              .approve(
                "0x692937e390C48600D908F0222108769449C3620C",
                "999999999999999999",
              )
    
            await tx1.wait()
          }
          setRedeemingStatus("Redeeming")

          const tx2 = await redemptionWithSigner
            .connect(signer)
            .redeem(BigInt(balance) * BigInt(100000000))
    
          await tx2.wait()
        } catch (e) {
          console.error(e)
        } finally {
          fetchBalance()
          setRedeemingStatus("idle")
        }

      }
    }

  const getUserBalance = async () => {

    console.log("getUserBalance")

    if (!wallet) return

    const userAddress = wallet.accounts[0].address

    const provider = new ethers.BrowserProvider(wallet.provider, "any")

    const chainId = (await provider.getNetwork()).chainId

    if (Number(chainId) !== 56) {
      return ("Please select BSC")
    }

    const signer = await provider.getSigner()

    const BTDMDContract = new ethers.Contract(
      "0x669288ADA63ed65Eb3770f1c9eeB8956deDAaa47",
      BTDMD_CONTRACT_ABI,
      signer,
    )

    console.log(userAddress)

    console.log(BTDMDContract)

    // User BTDMDBalance:
    var userBTDMDBalance =
      Number((BigInt(await BTDMDContract.balanceOf(userAddress))) /
      BigInt(10 ** 8))

    console.log(userBTDMDBalance)
    
    return userBTDMDBalance.toString()
  }

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          alignItems: "right",
          justifyContent: "right",
          height: "100%",
          width: "100%",
          marginTop: "10px"
        }}
      >
        <button
          style={{
            maxWidth: "100px",
            height: "40px",
            width: "100%",
            display: "inline-block",
            cursor: "pointer",
            borderRadius: "10px",
            marginTop: "10px",
            marginRight: "10px",
          }}
          onClick={() => {
            handleConnect(
            )
          }}
        >
          {wallet
              ? "Disconnect"
              : "Connect"}
        </button>
      </Box>

      <Box sx={{
        display: "flex",
        flexDirection: "column", // Set the direction to column
        justifyContent: "centre",
        alignItems: "center", // Center items vertically
        width: "100%",
        marginTop: "100px",
        color: "whitesmoke"
      }}>

        <img src="logo.png" alt="BTDMD" style={{ maxWidth:"100px" }} />
        
        <div style={{ textAlign: "center", marginTop: "20px" }}>
          BTDMD is winding down. Thank you for being with us!
          <br></br>
          <br></br>
          Token redemption is open for one year
          <br></br>
          (Feb 10 2023 to Feb 9 2024).
          <br></br>
        </div>

        <div style={{ textAlign: "center", marginTop: "20px" }}>
          Redeem at a market beating rate with ZERO tax.
          <br></br>
          <br></br>
          <a
            href="https://bscscan.com/address/0x692937e390C48600D908F0222108769449C3620C"
            target="_blank"
            rel="noreferrer"
            style={{ color: "yellow" }} // Change the color to your desired color
          >
            Redemption Contract on BSCScan
          </a>
        </div>

        <div style={{
          border: "1px solid white", // 1px solid black border
          borderRadius: "10px",
          padding: "10px", // Optional: Add padding for spacing inside the border
          marginLeft: "10px",
          marginRight: "10px",
          marginTop: "20px" 
        }}>
          <div style={{ textAlign: "left"}}>

            You will sign two transactions:
            <br></br>
            1) Approve the redemption contract to burn your BTDMD.
            <br></br>
            2) Redeem and get your BUSD.

          </div>

          <div style={{ textAlign: "center", marginTop: "20px" }}>
            <label >
              <strong>BTDMD balance:{" "}</strong>
              <span>
                <strong>
                {!wallet
                  ? "Connect to see balance"
                  : balance}
                </strong>
              </span>
            </label>
          </div>

          <div style={{ textAlign: "center", marginTop: "20px" }}>
            <button
              style={{
                maxWidth: "250px",
                height: "40px",
                width: "100%",
                borderRadius: "10px",
                backgroundColor: (balance === "Please select BSC") || (wallet && balance === "0") || redeemingStatus !== "idle" ? "#ccc" : "", // Change background color when disabled
                color: (balance === "Please select BSC") || (wallet && balance === "0") || redeemingStatus !== "idle" ? "#666" : "", // Change text color when disabled
                cursor: (balance === "Please select BSC") || (wallet && balance === "0") || redeemingStatus !== "idle" ? "not-allowed" : "pointer", // Change cursor when disabled
              }}
              onClick={() => {
                handleRedeem(
                )
              }}
              disabled={(balance === "Please select BSC") || (wallet && balance === 0) || redeemingStatus !== "idle"}
            >
              {wallet
                  ? redeemingStatus === "idle" ? "Redeem" : redeemingStatus
                  : "Connect"}
            </button>
          </div>
        </div>
      </Box>
    </div>
  )
}

const RedemptionComponent = () => (
  <Redemption />
)

export default RedemptionComponent
