export const REDEMPTION_CONTRACT_ABI = [
  {
    inputs: [],
    stateMutability: "nonpayable",
    type: "constructor",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "uint256",
        name: "BitDiamondAmount",
        type: "uint256",
      },
      {
        indexed: false,
        internalType: "uint256",
        name: "BUSDAmount",
        type: "uint256",
      },
    ],
    name: "BitDiamondRedemption",
    type: "event",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "amountBTDMD",
        type: "uint256",
      },
    ],
    name: "redeem",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
]
