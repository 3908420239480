import React from "react"
import { ThemeProvider, createTheme } from "@mui/material/styles"

import injectedModule from "@web3-onboard/injected-wallets"
import ledgerModule from "@web3-onboard/ledger"
import walletConnectModule from "@web3-onboard/walletconnect"
import trezorModule from "@web3-onboard/trezor"
import gnosisModule from "@web3-onboard/gnosis"
import coinbaseWalletModule from "@web3-onboard/coinbase"
import frameModule from "@web3-onboard/frame"
import { init } from "@web3-onboard/react"
import { Buffer } from "buffer"
import ReactModal from "react-modal"
import RedemptionComponent from "./RedemptionComponent"

window.Buffer = window.Buffer || Buffer
ReactModal.setAppElement("#root")

// =====================================================
// Setup Web3-onboard
// vvvvvvvvvvvvvvvvvv
// =====================================================
const injected = injectedModule()
const walletConnect = walletConnectModule();
const ledger = ledgerModule();

const trezor = trezorModule({
  email: 'support@btdmd.com',
  appUrl: "https://www.btdmd.com/",
})
const gnosis = gnosisModule()
const coinbaseWalletSdk = coinbaseWalletModule({ darkMode: true })
const frame = frameModule()
const appMetadata = {
  name: "connected to btdmd",
  icon: "logo.svg",

  description: "bitdiamond redemption",
}
const theme = {
  "--w3o-background-color": "#3d3d3d",
  "--w3o-foreground-color": "black",
  "--w3o-text-color": "#fff",
  "--w3o-border-color": "#e7d6bd",
  "--w3o-action-color": "#e7d6bd",
  "--w3o-border-radius": "10px",
}
const rpc = {
  rpc_1: "https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
  rpc_42161: "https://arb1.arbitrum.io/rpc",
  rpc_10: "https://optimism.publicnode.com",
  rpc_137: "https://endpoints.omniatech.io/v1/matic/mainnet/public",
  rpc_5: "https://goerli.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161",
  rpc_11155111: "https://ethereum-sepolia.blockpi.network/v1/rpc/public",
  rpc_421613: "https://goerli-rollup.arbitrum.io/rpc",
  rpc_420: "https://endpoints.omniatech.io/v1/op/goerli/public",
  rpc_80001: "https://endpoints.omniatech.io/v1/matic/mumbai/public",
}

init({
  theme,
  wallets: [
    injected,
    ledger,
    walletConnect,
    trezor,
    gnosis,
    coinbaseWalletSdk,
    frame,
  ],
  chains: [
    {
      id: 56,
      token: "BNB",
      label: "BSC",
      rpcUrl: rpc["rpc_1"],
    },
    {
      id: 1,
      token: "ETH",
      label: "Eth Mainnet",
      rpcUrl: rpc["rpc_1"],
    },
    {
      id: 42161,
      token: "ETH",
      label: "Arbitrum One",
      rpcUrl: rpc["rpc_42161"],
    },
    {
      id: 10,
      token: "ETH",
      label: "Optimism",
      rpcUrl: rpc["rpc_10"],
    },
    {
      id: 137,
      token: "MATIC",
      label: "Polygon",
      rpcUrl: rpc["rpc_137"],
    },
    {
      id: 5,
      token: "ETH",
      label: "Eth Goerli",
      rpcUrl: rpc["rpc_5"],
    },
    {
      id: 11155111,
      token: "ETH",
      label: "Eth Sepolia",
      rpcUrl: rpc["rpc_11155111"],
    },
    {
      id: 421613,
      token: "ETH",
      label: "Arbitrum Goerli",
      rpcUrl: rpc["rpc_421613"],
    },
    {
      id: 420,
      token: "ETH",
      label: "Optimism Goerli",
      rpcUrl: rpc["rpc_420"],
    },
    {
      id: 80001,
      token: "MATIC",
      label: "Polygon Mumbai",
      rpcUrl: rpc["rpc_80001"],
    },
  ],
  appMetadata,
})
// =====================================================
// ^^^^^^^^^^^^^^^^^^
// Setup Web3-onboard
// =====================================================

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
})

function App() {
  return (
    <ThemeProvider theme={darkTheme}>
      <div
        style={{
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          background: "black",
        }}
      >
        <div style={{ flex: 1, overflowY: "auto" }}>
          <RedemptionComponent />
        </div>
      </div>
    </ThemeProvider>
  )
}

export default App
